import React, { useEffect, useState } from 'react'
import { employerActions } from 'redux/employer/employerSlice'
import Button from 'core-system/Button'
import Modal, { ModalBody, ModalFooter, ModalHeader } from 'core-system/Modal'
import pxToRem from 'core-system/utils/pxToRem'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import styled from 'styled-components'
import { FinchWorksite } from 'redux/employer/employerTypes'
import Text from 'core-system/Text'
import Radio from 'core-system/Radio'
import palette from 'core-system/Themes/palette'
import Loading from 'core-system/Loading'
import { Success } from 'core-system/Icons/Misc'
import employerService from 'redux/employer/employerService'
import { employeeActions } from 'redux/employee/employeeSlice'

const SuccessContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`

const SuccessButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 1rem;
`

interface FinchWorksiteSelectModalProps {
  open: boolean
  closeModal: (e: React.MouseEvent<any>) => void
}

const FinchWorksiteSelectModal = (props: FinchWorksiteSelectModalProps) => {
  const { open, closeModal } = props

  const [selectedWorksite, setSelectedWorksite] = useState(null)
  const [loading, setLoading] = useState(false)
  const [syncStatus, setSyncStatus] = React.useState('off')
  const dispatch = useDispatch()

  // Fetch all Finch Worksites when the modal opens
  useEffect(() => {
    if (open) {
      dispatch(employerActions.getFinchWorksites())
    }
  }, [open, dispatch])

  // Indicate that a sync has started
  useEffect(() => {
    if (loading) {
      setSyncStatus('syncing')
    }
  }, [loading])

  const allFinchWorksites = useSelector(
    (state: AppState) => state.employer.allFinchWorksites
  )

  useEffect(() => {
    const syncedWorksite = allFinchWorksites?.filter(
      (worksite) => worksite.id === selectedWorksite
    )
    if (syncStatus === 'syncing' && syncedWorksite[0].sync) {
      setSelectedWorksite(null)
      setSyncStatus('success')
    }
  }, [allFinchWorksites, syncStatus, selectedWorksite])

  const renderSyncableFinchWorksiteRadios = (
    allFinchWorksites: FinchWorksite[]
  ) =>
    allFinchWorksites
      .filter((finchWorksite) => finchWorksite.canSync)
      .map((finchWorksite) => (
        <Radio
          active={selectedWorksite === finchWorksite.id}
          label={finchWorksite.address}
          onClick={() => setSelectedWorksite(finchWorksite.id)}
          marginRight='1rem'
          marginY='1rem'
          key={finchWorksite.id}
        />
      ))

  const handleSyncWorksite = () => {
    setLoading(true)
    employerService
      .syncFinchWorksiteEmployees(selectedWorksite)
      .then((res) =>
        dispatch(employerActions.syncFinchWorksiteEmployeesSuccess(res))
      )
      .then(() => dispatch(employeeActions.getCommuters()))
      .then(() => dispatch(employerActions.getWorksites()))
      .then(() => dispatch(employerActions.getFinchWorksites()))
      .then(() => setLoading(false))
  }

  const handleSyncAnother = () => {
    setSyncStatus('off')
  }

  const handleDone = (e: React.MouseEvent<HTMLButtonElement>) => {
    setSyncStatus('off')
    closeModal(e)
  }

  return (
    <Modal open={open} onClose={closeModal} width={pxToRem(514)}>
      {syncStatus !== 'success' && <ModalHeader title='Sync Finch Worksite' />}
      <ModalBody paddingBottom='1.5rem' paddingTop='1.5rem'>
        {/* <FinchWorksites> */}
        {loading && <Loading />}
        {syncStatus === 'success' && (
          <div>
            <SuccessContainer>
              <Success
                color={palette.secondary.green2}
                height={50}
                width={50}
                style={{ marginBottom: '1rem' }}
              />
              <Text variant='action1'>Worksite successfully synced!</Text>
            </SuccessContainer>
            <SuccessButtons>
              <Button variant='secondary' onClick={handleDone} width='45%'>
                Done
              </Button>
              <Button onClick={handleSyncAnother} width='45%'>
                Sync Another
              </Button>
            </SuccessButtons>
          </div>
        )}
        {!loading &&
          syncStatus === 'off' &&
          (allFinchWorksites?.filter((finchWorksite) => finchWorksite.canSync)
            .length === 0 ? (
            <Text
              variant='h5'
              style={{
                color: palette.grey.grey4,
                textAlign: 'center',
              }}
            >
              NO WORKSITES AVAILABLE TO SYNC
            </Text>
          ) : (
            <div>
              <Text variant='action2'>Select a Finch Worksite to sync:</Text>
              <div>
                {allFinchWorksites
                  ? renderSyncableFinchWorksiteRadios(allFinchWorksites)
                  : null}
              </div>
            </div>
          ))}
        {/* </FinchWorksites> */}
      </ModalBody>
      {syncStatus === 'off' && (
        <ModalFooter>
          <Button
            onClick={handleSyncWorksite}
            marginBottom='1.5rem'
            marginTop='1.5rem'
            width='100%'
            disabled={selectedWorksite ? false : true}
          >
            Sync Worksite
          </Button>
        </ModalFooter>
      )}
    </Modal>
  )
}

export default FinchWorksiteSelectModal
