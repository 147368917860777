import { AxiosResponse } from 'axios'
import Request from 'redux/config/services/Request'
import utils from 'redux/config/services/utils'
import {
  CreateSegmentParams,
  CreateWorksiteParams,
  EmployerApiType,
  PaymentMethodType,
  RolloutUsersParams,
  SegmentParams,
  UpdateSegmentEmpsParams,
  UpdateSegmentParams,
  Worksite,
  WorksiteApiType,
  WorksiteIdParams,
  ApplyDiscountParams,
  UpdateSegmentEmpsORCAParams,
} from './employerTypes'
import { OnboardingStep } from 'features/Onboarding/GettingStartedView'

const getEmployerInfo = (): Promise<AxiosResponse> => {
  return Request.get('/hr/profile/employer/')
}

const getSegments = (): Promise<AxiosResponse> => {
  return Request.get('/hr/profile/segments/')
}

const createSegment = (params: CreateSegmentParams): Promise<AxiosResponse> => {
  return Request.post('/hr/profile/segments/', {
    ...params,
    shippingDetails: null,
  })
}

const updateSegment = (params: UpdateSegmentParams): Promise<AxiosResponse> => {
  const { segmentId, ...rest } = params
  return Request.put(`/hr/profile/segments/${segmentId}/`, { ...rest })
}

const deleteSegment = (params: SegmentParams): Promise<AxiosResponse> => {
  return Request.del(`/hr/profile/segments/${params.segmentId}/`)
}

const addSegmentEmployees = (params: UpdateSegmentEmpsParams) => {
  return Request.post(
    `/hr/profile/segments/${params.segmentId}/add_employees/`,
    { employees: params.employees }
  )
}

const addSegmentEmployeesWithORCA = (params: UpdateSegmentEmpsORCAParams) => {
  return Request.post(
    `/hr/profile/segments/${params.segmentId}/add_employees/`,
    {
      employees: params.employees,
      orca_card_numbers: params.ORCA_card_numbers,
    }
  )
}

const removeSegmentEmployees = (params: UpdateSegmentEmpsParams) => {
  return Request.post(
    `/hr/profile/segments/${params.segmentId}/remove_employees/`,
    { employees: params.employees }
  )
}

const activateSegmentUpcomingAdditions = (params: UpdateSegmentEmpsParams) => {
  return Request.post(
    `/hr/profile/segments/${params.segmentId}/force_upcoming_additions/`,
    { employees: params.employees }
  )
}

const removeSegmentUpcomingRemovals = (params: UpdateSegmentEmpsParams) => {
  return Request.post(
    `/hr/profile/segments/${params.segmentId}/force_upcoming_removals/`,
    { employees: params.employees }
  )
}

const forceActivateInactiveEmployees = (params: UpdateSegmentEmpsParams) => {
  return Request.post(`/hr/profile/segments/approve_employees/`, {
    employees: params.employees,
  })
}

const declineInactiveEmployees = (params: UpdateSegmentEmpsParams) => {
  return Request.post(`/hr/profile/segments/decline_employees/`, {
    employees: params.employees,
  })
}

const getWorksites = (): Promise<AxiosResponse> => {
  return Request.get('/hr/profile/worksites/')
}

interface UpdateEmployerParams extends EmployerApiType {}

const updateEmployer = (data: UpdateEmployerParams): Promise<AxiosResponse> => {
  if (typeof data.avatar === 'string' || data.avatar === null) {
    const formatted =
      data.avatar === null ? data : utils.removeKey('avatar', data)
    return Request.put(`/hr/profile/employer/`, formatted)
  }
  const formData = utils.getFormData(data)
  return Request.put(`/hr/profile/employer/`, formData, true)
}

const updatePrimaryAcctHolder = (
  primaryAccountHolder: string
): Promise<AxiosResponse> => {
  return Request.patch(`/hr/profile/employer/`, {
    primaryAccountHolder: primaryAccountHolder,
  })
}

interface UpdateWorksiteParams extends WorksiteApiType {}

const updateWorksite = (data: UpdateWorksiteParams): Promise<AxiosResponse> => {
  return Request.put(`/hr/profile/worksites/${data.id}/`, data)
}

const createWorksite = (
  worksite: CreateWorksiteParams
): Promise<AxiosResponse> => {
  return Request.post('/hr/profile/worksites/', worksite)
}

const getWorksiteInviteId = (worksite: Worksite): Promise<AxiosResponse> => {
  return Request.get(`/hr/profile/worksites/${worksite.id}/toggle_invite/`)
}

const disableWorksiteInviteId = (
  worksiteId: string
): Promise<AxiosResponse> => {
  return Request.del(`/hr/profile/worksites/${worksiteId}/toggle_invite/`)
}

const archiveWorksite = (params: WorksiteIdParams): Promise<AxiosResponse> => {
  return Request.del(`/hr/profile/worksites/${params.worksiteId}`)
}

const unarchiveWorksite = (
  params: WorksiteIdParams
): Promise<AxiosResponse> => {
  return Request.post(
    `/hr/profile/worksites/${params.worksiteId}/unarchive/`,
    {}
  )
}

const getFileUploadHistory = (): Promise<AxiosResponse> => {
  return Request.get('/hr/profile/employer_file_uploads/')
}

const uploadFile = (data: FormData): Promise<AxiosResponse> => {
  return Request.post('/hr/profile/employer_file_uploads/', data, 'formData')
}

const integrateHris = (code: string): Promise<AxiosResponse> => {
  return Request.post('/hr/profile/hris_login/', { code: code })
}

const hrisSync = (): Promise<AxiosResponse> => {
  return Request.post('/hr/profile/finch_data_sync/', null)
}

// ACTION ITEM: add api path when ready
const disconnectHris = (): Promise<AxiosResponse> => {
  return Request.post('/hr/profile/hris_disconnect/', null)
}

const getFinchWorksites = (): Promise<AxiosResponse> => {
  return Request.get('/hr/profile/finch_worksites/')
}

const syncFinchWorksiteEmployees = (
  finchWorksiteId: string
): Promise<AxiosResponse> => {
  return Request.post(
    `/hr/profile/finch_worksites/${finchWorksiteId}/sync_employees/`,
    null
  )
}

const bulkSyncFinchWorksiteEmployees = (
  finchWorksiteIds: string[]
): Promise<AxiosResponse> => {
  return Request.post(
    `/hr/profile/finch_worksites/sync_multiple_worksites_employees/`,
    {
      worksite_ids: finchWorksiteIds,
    }
  )
}

const manageConnectedAccount = (
  refreshUrl: string,
  returnUrl: string,
  isUpdate?: boolean
): Promise<AxiosResponse> => {
  return Request.post(
    '/hr/profile/stripe_link/',
    {
      is_update: isUpdate || false,
      refresh_url: refreshUrl,
      return_url: returnUrl,
    },
    null,
    true
  )
}

const applyDiscount = (params: ApplyDiscountParams): Promise<AxiosResponse> => {
  const data = {
    employer_id: params.employerId,
    promo_code: params.promoCode,
  }
  return Request.post('/hr/profile/stripe_billing/apply_discount/', data)
}

// For Platform payment, get client secret id
const getClientPaymentId = (isLinkToken?: boolean): Promise<AxiosResponse> => {
  return Request.post(
    '/hr/profile/stripe_billing/',
    isLinkToken ? { isLinkToken: true } : {},
    null,
    true
  )
}

const getPlatformBillingInfo = (): Promise<AxiosResponse> => {
  return Request.get('/hr/profile/stripe_billing/')
}

const updatePlatformBillingInfo = (
  data: PaymentMethodType
): Promise<AxiosResponse> => {
  return Request.put(`/hr/profile/stripe_billing/${data.id}/`, data)
}

const getEmployerInvoices = (): Promise<AxiosResponse> => {
  return Request.get(`/hr/profile/stripe_billing/fleet_invoices/`)
}

const removePaymentMethod = (id: string): Promise<AxiosResponse> => {
  return Request.del(`/hr/profile/stripe_billing/${id}/`)
}

const getStripeBankAccountSecret = () => {
  return Request.post('/hr/profile/stripe_billing/', {}, null, true)
}

const getPlaidToken = () => {
  return Request.get('/hr/profile/plaid_link/', true)
}

const passPlaidToken = (info: { token: string; accountId: string }) => {
  const data = {
    publicToken: info.token,
    accountId: info.accountId,
  }
  return Request.post('/hr/profile/plaid_link/', data)
}

const removePlaidLink = (id: string): Promise<AxiosResponse> => {
  return Request.del(`/hr/profile/plaid_link/${id}/`)
}

const rolloutUsers = (params: RolloutUsersParams) => {
  return Request.post('/hr/profile/segments/rollout_users/', {
    employees: params.employees,
  })
}

const updateOnboardingStep = (step: OnboardingStep) => {
  return Request.patch('/hr/profile/employer/', { lastOnboardingStep: step })
}

export default {
  getEmployerInfo,

  getSegments,
  createSegment,
  updateSegment,
  deleteSegment,
  addSegmentEmployees,
  addSegmentEmployeesWithORCA,
  removeSegmentEmployees,
  activateSegmentUpcomingAdditions,
  removeSegmentUpcomingRemovals,
  forceActivateInactiveEmployees,
  declineInactiveEmployees,

  getWorksites,
  updateEmployer,
  updatePrimaryAcctHolder,
  updateWorksite,
  createWorksite,
  archiveWorksite,
  unarchiveWorksite,
  getWorksiteInviteId,
  disableWorksiteInviteId,
  getFileUploadHistory,
  uploadFile,
  integrateHris,
  hrisSync,
  disconnectHris,
  getFinchWorksites,
  syncFinchWorksiteEmployees,
  bulkSyncFinchWorksiteEmployees,
  getClientPaymentId,
  manageConnectedAccount,
  getPlatformBillingInfo,
  updatePlatformBillingInfo,
  removePaymentMethod,
  getPlaidToken,
  passPlaidToken,
  removePlaidLink,
  getEmployerInvoices,
  getStripeBankAccountSecret,
  rolloutUsers,
  applyDiscount,
  updateOnboardingStep,
}
