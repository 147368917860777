import FlexContainer from 'core-system/FlexContainer'
import React from 'react'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import styled from 'styled-components'
import Text from 'core-system/Text'
import FormattingUtils from 'shared/FormattingUtils'
import palette from 'core-system/Themes/palette'
import * as FlexIcons from 'core-system/Icons/Flex'
import Divider from 'core-system/Divider'
import QTFUtils from 'features/QTF/Shared/QTFUtils'

const IconContainer = styled(FlexContainer)`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  margin-right: 0.25rem;
`

const renderUpcomingAllocation = (
  amount: number,
  maxAmount: number,
  type: 'Transit' | 'Parking' | 'ORCA',
  icon: React.ReactNode
) => {
  return (
    <FlexContainer flexDirection='column' alignItems='center'>
      {/* HEADER */}
      <FlexContainer flexDirection='row' alignItems='center'>
        <IconContainer center>{icon}</IconContainer>
        <Text variant='action1'>{type}</Text>
      </FlexContainer>
      {/* AMOUNT */}
      <FlexContainer
        flexDirection='column'
        alignItems='center'
        marginLeft='1rem'
      >
        <Text variant='h2' textColor={palette.primary.primaryPurple}>
          {FormattingUtils.formatDollar(amount, 0)}
        </Text>
        <Text variant='action3' textColor={palette.text.placeholder}>
          {`(max. ${FormattingUtils.formatDollar(maxAmount, 0)})`}
        </Text>
      </FlexContainer>
    </FlexContainer>
  )
}

interface UpcomingAllocationsProps {
  hasORCA: boolean
}

const UpcomingAllocations = React.memo((props: UpcomingAllocationsProps) => {
  const { hasORCA } = props

  const { preTaxPrograms } = useSelector(
    (state: AppState) => state.employeePlatform
  )

  return (
    <FlexContainer
      flexDirection='row'
      alignItems='center'
      justifyContent='space-around'
      marginBottom='0.5rem'
      marginX='3rem'
    >
      {preTaxPrograms.COMMUTER_ORCA && hasORCA
        ? renderUpcomingAllocation(
            preTaxPrograms.COMMUTER_ORCA?.employeeDeduction?.employeeDeduction,
            QTFUtils.monthlyPreTaxTransit -
              preTaxPrograms.COMMUTER_TRANSIT.employerContribution -
              preTaxPrograms.COMMUTER_TRANSIT.employeeDeduction
                ?.employeeDeduction,
            'ORCA',
            <FlexIcons.Transit color={palette.text.secondary} />
          )
        : null}
      {preTaxPrograms.COMMUTER_ORCA && hasORCA ? (
        <Divider size='5rem' direction='vertical' margin='0 1.5rem' />
      ) : null}
      {preTaxPrograms.COMMUTER_TRANSIT
        ? renderUpcomingAllocation(
            preTaxPrograms.COMMUTER_TRANSIT?.employeeDeduction
              ?.employeeDeduction,
            QTFUtils.monthlyPreTaxTransit -
              preTaxPrograms.COMMUTER_TRANSIT.employerContribution -
              preTaxPrograms.COMMUTER_ORCA?.employeeDeduction
                ?.employeeDeduction,
            'Transit',
            <FlexIcons.Transit color={palette.text.secondary} />
          )
        : null}
      {preTaxPrograms.COMMUTER_TRANSIT && preTaxPrograms.COMMUTER_PARKING ? (
        <Divider size='5rem' direction='vertical' margin='0 1.5rem' />
      ) : null}
      {preTaxPrograms.COMMUTER_PARKING
        ? renderUpcomingAllocation(
            preTaxPrograms.COMMUTER_PARKING?.employeeDeduction
              ?.employeeDeduction,
            QTFUtils.monthlyPreTaxParking -
              preTaxPrograms.COMMUTER_PARKING.employerContribution,
            'Parking',
            <FlexIcons.Parking color={palette.text.secondary} />
          )
        : null}
      {!preTaxPrograms.COMMUTER_PARKING && !preTaxPrograms.COMMUTER_TRANSIT ? (
        <FlexContainer alignItems='center'>
          <Text variant='h4' textColor={palette.text.placeholder}>
            NO ACTIVE PRE-TAX COMMUTER BENEFITS PROGRAMS
          </Text>
        </FlexContainer>
      ) : null}
    </FlexContainer>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  UpcomingAllocations.displayName = 'UpcomingAllocations'
}

export default UpcomingAllocations
