import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  CreateSegmentParams,
  CreateWorksiteParams,
  Employer as EmployerProfileState,
  EmployerSuccessPayload,
  FileUploadHistory,
  FileUploadSuccessPayload,
  FinchWorksite as FinchWorksiteType,
  PaymentMethodType,
  Segment,
  SegmentParams,
  UpdateSegmentEmpsParams,
  UpdateSegmentParams,
  Worksite,
  Worksite as WorksiteType,
  WorksiteIdParams,
  WorksiteSuccessPayload,
  EmployerInvoice,
  RolloutUsersParams,
  IntegrateHrisPayload,
  Discount,
  ApplyDiscountParams,
  UpdateSegmentEmpsORCAParams,
} from 'redux/employer/employerTypes'
import { userActions } from 'redux/user/userSlice'

export interface EmployerState {
  profile: EmployerProfileState | null
  worksites: Array<WorksiteType> | null

  allSegments: Segment[]
  segmentsMap: Dictionary<Segment>
  segmentsType: Dictionary<Segment[]>
  segmentUpdateFlag: string
  segmentCreateFlag: string
  allEmployeeSegmentId: string
  totalEmployees: number
  pendingEmployees: Dictionary<string[]>

  fileUploadHistory: Array<FileUploadHistory> | null
  isUploading: number | null
  worksiteCreatedFlag: boolean

  hasValidPaymentMethod: boolean
  hasValidDistributionType: boolean
  employerInvoices: EmployerInvoice[]

  employeesDeclinedFlag: boolean
  //set to a number to calculate how long client should wait
  //to call for new emp data else false
  employeesApprovedFlag: number | boolean

  allFinchWorksites: FinchWorksiteType[]
  finchWorksiteSyncLoading: boolean
  finchConnectLoading: boolean
  rolloutUsersFlag: boolean

  discount: Discount
}

const initialState: EmployerState = {
  profile: null,
  worksites: null,

  allSegments: null,
  segmentsMap: null,
  segmentsType: null,
  segmentUpdateFlag: null,
  segmentCreateFlag: null,
  allEmployeeSegmentId: null,
  totalEmployees: null,
  pendingEmployees: null,

  fileUploadHistory: null,
  isUploading: null,
  worksiteCreatedFlag: false,

  hasValidPaymentMethod: null,
  hasValidDistributionType: null,
  employerInvoices: null,

  employeesDeclinedFlag: false,
  employeesApprovedFlag: false,

  allFinchWorksites: null,
  finchWorksiteSyncLoading: false,
  finchConnectLoading: false,
  rolloutUsersFlag: false,

  discount: null,
}

const getWorksiteInviteIdSuccess = createAction(
  'employer/getWorksiteInviteIdSuccess'
)
const archiveWorksiteSuccess = createAction('employer/archiveWorksiteSuccess')
const unarchiveWorksiteSuccess = createAction(
  'employer/unarchiveWorksiteSuccess'
)
const sendPlaidLinkToken = createAction<{ token: string; accountId: string }>(
  'employer/sendPlaidLinkToken'
)
const sendPlaidLinkTokenSuccess = createAction(
  'employer/sendPlaidLinkTokenSuccess'
)
const removePlaidLinkSuccess = createAction('employer/removePlaidLinkSuccess')
const getEmployer = createAction('employer/getEmployer')
const getBillingInfo = createAction('employer/getBillingInfo')
const updateBillingInfo = createAction<PaymentMethodType>(
  'employer/updateBillingInfo'
)
const getEmployerInvoices = createAction('employer/getEmployerInvoices')
const removePaymentMethod = createAction<string>('employer/removePaymentMethod')

const removePlaidLink = createAction<string>('employer/removePlaidLink')
const updateEmployer = createAction<EmployerSuccessPayload>(
  'employer/updateEmployer'
)
const updatePrimaryAcctHolder = createAction<string>(
  'employer/updatePrimaryAcctHolder'
)
const getSegments = createAction('employer/getSegments')
const archiveEmployeeSuccess = createAction('employer/archiveEmployeeSuccess')
const reactivateEmployeeSuccess = createAction(
  'employer/reactivateEmployeeSuccess'
)
const createSegment = createAction<CreateSegmentParams>(
  'employer/createSegment'
)
const updateSegment = createAction<UpdateSegmentParams>(
  'employer/updateSegment'
)
const deleteSegment = createAction<SegmentParams>('employer/deleteSegment')
const addSegmentEmployees = createAction<UpdateSegmentEmpsParams>(
  'employer/addSegmentEmployees'
)
const addSegmentEmployeesWithORCA = createAction<UpdateSegmentEmpsORCAParams>(
  'employer/addSegmentEmployeesWithORCA'
)
const removeSegmentEmployees = createAction<UpdateSegmentEmpsParams>(
  'employer/removeSegmentEmployees'
)
const activateSegmentUpcomingAdditions = createAction<UpdateSegmentEmpsParams>(
  'employer/activateSegmentUpcomingAdditions'
)
const removeSegmentUpcomingRemovals = createAction<UpdateSegmentEmpsParams>(
  'employer/removeSegmentUpcomingRemovals'
)
const forceActivateInactiveEmployees = createAction<UpdateSegmentEmpsParams>(
  'employer/forceActivateInactiveEmployees'
)
const declineInactiveEmployees = createAction<UpdateSegmentEmpsParams>(
  'employer/declineInactiveEmployees'
)
const getWorksites = createAction('employer/getWorksites')
const updateWorksite = createAction<WorksiteSuccessPayload>(
  'employer/updateWorksite'
)
const createWorksite = createAction<CreateWorksiteParams>(
  'employer/createWorksite'
)
const getWorksiteInviteId = createAction<Worksite>(
  'employer/getWorksiteInviteId'
)
const disableWorksiteInviteId = createAction<string>(
  'employer/disableWorksiteInviteId'
)
const archiveWorksite = createAction<WorksiteIdParams>(
  'employer/archiveWorksite'
)
const unarchiveWorksite = createAction<WorksiteIdParams>(
  'employer/unarchiveWorksite'
)
const getFileUploadHistory = createAction('employer/getFileUploadHistory')
const integrateHris = createAction<string>('employer/integrateHris')
const hrisSync = createAction('employer/hrisSync')
const disconnectHris = createAction('employer/disconnectHris')
const getFinchWorksites = createAction('employer/getFinchWorksites')
const syncFinchWorksiteEmployees = createAction<string>(
  'employer/syncFinchWorksiteEmployees'
)
const bulkSyncFinchWorksiteEmployees = createAction<string[]>(
  'employer/bulkSyncFinchWorksiteEmployees'
)

const rolloutUsers = createAction<RolloutUsersParams>('employer/rolloutUsers')
const applyDiscount = createAction<ApplyDiscountParams>(
  'employer/applyDiscount'
)
const applyDiscountSuccess = createAction<Discount>(
  'employer/applyDiscountSuccess'
)

const updateOnboardingStep = createAction<string>(
  'employer/updateOnboardingStep'
)

const createWorksiteAlias = (address: string) => {
  const split = address.split(',')
  return {
    city: split[1],
    street: split[0],
  }
}

const formatSegment = (segment: Segment) => {
  return {
    ...segment,
    name: segment.name.split(',')[0].replace('Worksite ', '') || segment.name,
  }
}

const getFormattedBillingDetails = (
  data: PaymentMethodType
): PaymentMethodType => {
  if (!data) {
    return null
  }
  return data
}

const formatSegmentData = (segments: Segment[]) => {
  const segmentsData: {
    segmentsMap: Dictionary<Segment>
    types: Dictionary<Segment[]>
  } = segments.reduce(
    (agg, segment) => {
      const formattedSegment =
        segment.type === 'WORKSITE' ? formatSegment(segment) : segment
      agg.segmentsMap[segment.id] = agg.segmentsMap[segment.id] || {}
      agg.segmentsMap[segment.id] = formattedSegment
      if (segment.archived) {
        agg.types['archived'] = agg.types['archived'] || []
        agg.types['archived'].push(formattedSegment)
      } else {
        const type = segment.type.toLowerCase()
        agg.types[type] = agg.types[type] || []
        agg.types[type].push(formattedSegment)
      }
      return agg
    },
    { segmentsMap: {}, types: {} }
  )

  for (const type in segmentsData.types) {
    segmentsData.types[type] = segmentsData.types[type].sort(
      (a, b) => b.commuters - a.commuters
    )
  }
  return segmentsData
}

const handleUpdateSegmentSuccess = (state: EmployerState, action: any) => {
  const segmentsCopy = [...state.allSegments]
  const idx = segmentsCopy.findIndex(
    (segment) => segment.id === action.payload.id
  )
  segmentsCopy[idx] = action.payload
  const segmentData = formatSegmentData(segmentsCopy)
  return {
    ...state,
    allSegments: segmentsCopy,
    segmentsMap: segmentData.segmentsMap,
    segmentsType: segmentData.types,
    segmentUpdateFlag:
      action.payload.id || (action.meta && action.meta.segmentId) || null,
  }
}

const employerSlice = createSlice({
  name: 'employer',
  initialState,
  reducers: {
    getEmployerSuccess(state, action: PayloadAction<EmployerSuccessPayload>) {
      const emp = action.payload
      state.profile = {
        ...state.profile,
        id: emp.id,
        name: emp.name,
        domain: emp.domain,
        logoUrl: emp.avatar,
        clearbitUrl: emp.clearbitLogoUrl,
        payrollProviderId: emp.payrollProviderId,
        finchReauth: emp.finchNeedsReauth,
        connectedAccountDetails: emp.connectedAccountDetails,
        primaryAccountHolderId: emp.primaryAccountHolder,
        planDetails: emp.planDetails,
        promoCode: emp.promoCode,
        referralCredits: emp.referralCredits,
        products: emp.products,
        openRegistration: emp.openRegistration,
        lastOnboardingStep: emp.lastOnboardingStep,
      }
    },
    updateEmployerSuccess(
      state,
      action: PayloadAction<EmployerSuccessPayload>
    ) {
      const emp = action.payload
      state.profile = {
        ...state.profile,
        id: emp.id,
        name: emp.name,
        domain: emp.domain,
        logoUrl: emp.avatar,
        clearbitUrl: emp.clearbitLogoUrl,
        payrollProviderId: emp.payrollProviderId,
        finchReauth: emp.finchNeedsReauth,
        connectedAccountDetails: emp.connectedAccountDetails,
        primaryAccountHolderId: emp.primaryAccountHolder,
      }
    },
    updatePrimaryAcctHolderSuccess(
      state,
      action: PayloadAction<EmployerSuccessPayload>
    ) {
      const newPrimaryAcctHolderId = action.payload.primaryAccountHolder
      state.profile = {
        ...state.profile,
        primaryAccountHolderId: newPrimaryAcctHolderId,
      }
    },
    getWorksitesSuccess(
      state,
      action: PayloadAction<WorksiteSuccessPayload[]>
    ) {
      const raw = action.payload

      // get a random number between 2 - 5 as default office icon id (1 is for HQ)
      const getRandom = () => {
        return Math.floor(Math.random() * (5 - 2 + 1) + 2)
      }
      return {
        ...state,
        worksites: raw
          .sort((a, b) => b.numEmployees - a.numEmployees)
          .map((w, idx) => {
            return {
              id: w.id,
              alias: w.alias
                ? w.alias
                : createWorksiteAlias(w.formattedAddress).street,
              address: w.address,
              employeeCount: w.numEmployees,
              employeeArchivedCount: w.numArchivedEmployees,
              formattedAddress: w.formattedAddress || w.address,
              city: w.formattedAddress
                ? w.formattedAddress.split(',')[1].trim()
                : w.address.split(',')[1].trim(),
              metroArea: w.metroArea,
              inviteCode: w.inviteCode,
              expectedEmployees: w.expectedEmployees,
              iconId: idx === 0 ? 1 : getRandom(),
              cardsActivated:
                w.cardsActivated !== null ? w.cardsActivated : null,
              shippingDetails: !w.shippingDetails
                ? null
                : {
                    type: w.shippingDetails.shippingType || null,
                    address: w.shippingDetails.shippingAddress || null,
                  },
              unitNumber: w.unitNumber,
              archived: w.archived,
              archiveDate: w.archiveDate,
              segmentId: w.segmentId,
              canUseAutomatedBenefits: w.canUseAutomatedBenefits,
              finchWorksite: w.finchWorksite,
            }
          }),
      }
    },
    getSegmentsSuccess(state, action: PayloadAction<Segment[]>) {
      const segmentsData = formatSegmentData(action.payload)

      return {
        ...state,
        allSegments: action.payload
          .filter((segment) => !segment.archived)
          .map((segment) => formatSegment(segment)),
        segmentsMap: segmentsData.segmentsMap,
        segmentsType: segmentsData.types,
        allEmployeeSegmentId: segmentsData.types.all[0].id,
        totalEmployees: segmentsData.types.all[0].commuters,
        hasValidDistributionType:
          segmentsData.types.all[0].shippingDetails && true,
      }
    },
    toggleSegmentCreateFlag(state) {
      state.segmentCreateFlag = null
    },
    toggleSegmentUpdateFlag(state) {
      state.segmentUpdateFlag = null
    },
    createSegmentSuccess(state, action: PayloadAction<Segment>) {
      const newSegments = [...state.allSegments, action.payload]
      const segmentData = formatSegmentData(newSegments)

      return {
        ...state,
        allSegments: newSegments,
        segmentsMap: segmentData.segmentsMap,
        segmentsType: segmentData.types,
        segmentCreateFlag: action.payload.id,
      }
    },
    addSegmentEmployeesSuccess(state, action: PayloadAction<Segment>) {
      return handleUpdateSegmentSuccess(state, action)
    },
    addSegmentEmployeesWithORCASuccess(state, action: PayloadAction<Segment>) {
      return handleUpdateSegmentSuccess(state, action)
    },
    removeSegmentEmployeesSuccess(state, action: PayloadAction<Segment>) {
      return handleUpdateSegmentSuccess(state, action)
    },
    activateSegmentUpcomingAdditionsSuccess(
      state,
      action: PayloadAction<Segment>
    ) {
      return handleUpdateSegmentSuccess(state, action)
    },
    removeSegmentUpcomingRemovalsSuccess(
      state,
      action: PayloadAction<Segment>
    ) {
      return handleUpdateSegmentSuccess(state, action)
    },
    updateSegmentSuccess(state, action: PayloadAction<Segment>) {
      if (action.payload.type === 'ALL' && action.payload.shippingDetails) {
        return {
          ...state,
          segmentsType: {
            ...state.segmentsType,
            all: [action.payload],
          },
          hasValidDistributionType: true,
        }
      } else if (action.payload.type === 'CUSTOM') {
        const segmentsCopy = [...state.allSegments]
        const idx = segmentsCopy.findIndex(
          (segment) => segment.id === action.payload.id
        )
        segmentsCopy[idx] = action.payload
        const segmentData = formatSegmentData(segmentsCopy)

        return {
          ...state,
          allSegments: segmentsCopy,
          segmentsMap: segmentData.segmentsMap,
          segmentsType: segmentData.types,
          segmentUpdateFlag: action.payload.id,
        }
      } else {
        return state
      }
    },
    deleteSegmentSuccess(state, action: any) {
      const segmentsCopy = [...state.allSegments]
      const idx = segmentsCopy.findIndex(
        (segment) => segment.id === action.meta.segmentId
      )
      segmentsCopy.splice(idx, 1)

      return {
        ...state,
        allSegments: null,
        segmentsMap: null,
        segmentsType: null,
        segmentCreateFlag: null,
      }
    },
    forceActivateInactiveEmployeesSuccess(state, action: any) {
      state.segmentUpdateFlag = action.meta.segmentId
      state.employeesApprovedFlag = action.meta?.employees?.length
    },
    toggleForceActivateInactiveEmployeesFlag(state) {
      state.employeesApprovedFlag = false
    },
    disableWorksiteInviteIdSuccess(state, action: any) {
      state.worksites = state.worksites.map((worksite) =>
        worksite.id === action.meta
          ? { ...worksite, inviteCode: null }
          : worksite
      )
    },
    updateWorksiteSuccess(
      state,
      action: PayloadAction<WorksiteSuccessPayload>
    ) {
      const uw = action.payload
      const f = {
        id: uw.id,
        alias: uw.alias ? uw.alias : null,
        address: uw.address,
        employeeCount: uw.numEmployees,
        employeeArchivedCount: uw.numArchivedEmployees,
        formattedAddress: uw.formattedAddress,
        city: uw.formattedAddress.split(',')[1].trim(),
        cardsActivated: uw.cardsActivated !== null ? uw.cardsActivated : null,
        shippingDetails: !uw.shippingDetails
          ? null
          : {
              type: uw.shippingDetails.shippingType || null,
              address: uw.shippingDetails.shippingAddress || null,
            },
      }

      state.worksites = state.worksites.map((site) =>
        site.id === f.id ? { ...f, iconId: site.iconId } : site
      )
    },
    getFileUploadHistorySuccess(
      state,
      action: PayloadAction<FileUploadSuccessPayload[]>
    ) {
      // show last uploaded first
      const sortedArr =
        action.payload.length > 1
          ? action.payload.sort(
              (a, b) =>
                new Date(b.createdAt).getTime() -
                new Date(a.createdAt).getTime()
            )
          : action.payload

      state.fileUploadHistory = sortedArr.map((fh) => {
        return {
          id: fh.id,
          uploader: fh.uploader,
          employer: fh.employer,
          createdAt: fh.createdAt,
          filename: fh.filename,
          dataType: fh.datatype,
          fileUrl: fh.datafile,
        }
      })
    },
    uploadFileSuccess(state, action: PayloadAction<FileUploadSuccessPayload>) {
      const f = {
        id: action.payload.id,
        uploader: action.payload.uploader,
        employer: action.payload.employer,
        createdAt: action.payload.createdAt,
        filename: action.payload.filename,
        dataType: action.payload.datatype,
        fileUrl: action.payload.datafile,
      }

      const currentHistory = state.fileUploadHistory
        ? [...state.fileUploadHistory]
        : []
      currentHistory.unshift(f)

      state.fileUploadHistory = currentHistory
      state.isUploading = 2
    },
    uploadFile(state) {
      state.isUploading = 1
    },
    uploadFileFailed(state) {
      state.isUploading = -1
    },
    integrateHris(state) {
      state.finchConnectLoading = true
    },
    integrateHrisSuccess(state, action: PayloadAction<IntegrateHrisPayload>) {
      state.profile.payrollProviderId = action.payload.payrollProviderId
      state.profile.finchReauth = Boolean(action.payload.needsReauth)
      state.finchConnectLoading = false
    },
    integrateHrisFailed(state) {
      state.finchConnectLoading = false
    },
    hrisSync(state) {
      state.finchConnectLoading = true
    },
    hrisSyncSuccess(state) {
      state.finchConnectLoading = false
    },
    hrisSyncFailed(state) {
      state.finchConnectLoading = false
    },
    disconnectHrisSuccess(state) {
      state.profile.payrollProviderId = null
      state.profile.finchReauth = null
    },
    getFinchWorksitesSuccess(
      state,
      action: PayloadAction<FinchWorksiteType[]>
    ) {
      return {
        ...state,
        allFinchWorksites: action.payload,
      }
    },
    syncFinchWorksiteEmployees(state) {
      return {
        ...state,
        finchWorksiteSyncLoading: true,
      }
    },
    syncFinchWorksiteEmployeesSuccess(state, action: any) {
      return {
        ...state,
        allFinchWorksites: state.allFinchWorksites.map((worksite) =>
          worksite.id === action.meta ? { ...worksite, sync: true } : worksite
        ),
        finchWorksiteSyncLoading: false,
      }
    },
    bulkSyncFinchWorksiteEmployees(state) {
      return {
        ...state,
        finchWorksiteSyncLoading: true,
      }
    },
    bulkSyncFinchWorksiteEmployeesSuccess(state, action: any) {
      const syncedWorksites = new Set(action.payload.updated)
      return {
        ...state,
        allFinchWorksites: state.allFinchWorksites.map((worksite) =>
          syncedWorksites.has(worksite.id)
            ? { ...worksite, sync: true }
            : worksite
        ),
        finchWorksiteSyncLoading: false,
      }
    },
    getBillingInfoSuccess(state, action: PayloadAction<PaymentMethodType[]>) {
      return {
        ...state,
        profile: {
          ...state.profile,
          platformFeeDetails: action.payload.map((pm) =>
            getFormattedBillingDetails(pm)
          ),
        },
        hasValidPaymentMethod: action.payload.length > 0,
      }
    },
    updateBillingInfoSuccess(state, action: any) {
      const updatePaymentMethod =
        action.meta &&
        state.profile.platformFeeDetails.map((pm) => {
          if (pm.id === action.meta.id) {
            return {
              ...pm,
              billingDetails: action.meta.billingDetails,
            }
          }
          return pm
        })

      return {
        ...state,
        profile: {
          ...state.profile,
          platformFeeDetails: updatePaymentMethod,
        },
      }
    },
    getEmployerInvoicesSuccess(
      state,
      action: PayloadAction<EmployerInvoice[]>
    ) {
      state.employerInvoices = action.payload
    },
    removePaymentMethodSuccess(state, action: any) {
      return {
        ...state,
        profile: {
          ...state.profile,
          platformFeeDetails: state.profile.platformFeeDetails.filter(
            (pm) => pm.id !== action.meta
          ),
        },
      }
    },
    createWorksiteSuccess(state) {
      state.worksiteCreatedFlag = !state.worksiteCreatedFlag
    },
    toggleCreateWorksiteFlag(state) {
      state.worksiteCreatedFlag = !state.worksiteCreatedFlag
    },
    declineInactiveEmployeesSuccess(state) {
      state.employeesDeclinedFlag = !state.employeesDeclinedFlag
    },
    toggleDeclineInactiveEmployeesFlag(state) {
      state.employeesDeclinedFlag = !state.employeesDeclinedFlag
    },
    rolloutUsersSuccess(state) {
      state.rolloutUsersFlag = !state.rolloutUsersFlag
    },
    applyDiscountSuccess(state, action: PayloadAction<Discount>) {
      const discount = action.payload
      state.discount = {
        ...state.discount,
        message: discount.message,
        status: discount.status,
        couponDetails: discount.couponDetails,
      }
    },
    updateOnboardingStepSuccess(
      state,
      action: PayloadAction<EmployerSuccessPayload>
    ) {
      state.profile = {
        ...state.profile,
        lastOnboardingStep: action.payload.lastOnboardingStep,
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userActions.logout, () => {
      return {
        ...initialState,
      }
    })
  },
})

export const employerReducer = employerSlice.reducer
export const employerActions = {
  ...employerSlice.actions,
  getWorksiteInviteIdSuccess,
  archiveWorksiteSuccess,
  unarchiveWorksiteSuccess,
  sendPlaidLinkTokenSuccess,
  removePlaidLinkSuccess,
  getEmployer,
  getBillingInfo,
  updateBillingInfo,
  getEmployerInvoices,
  removePaymentMethod,
  sendPlaidLinkToken,
  removePlaidLink,
  updateEmployer,
  updatePrimaryAcctHolder,
  getSegments,
  archiveEmployeeSuccess,
  reactivateEmployeeSuccess,
  createSegment,
  updateSegment,
  deleteSegment,
  addSegmentEmployees,
  addSegmentEmployeesWithORCA,
  removeSegmentEmployees,
  activateSegmentUpcomingAdditions,
  removeSegmentUpcomingRemovals,
  forceActivateInactiveEmployees,
  declineInactiveEmployees,
  getWorksites,
  updateWorksite,
  createWorksite,
  getWorksiteInviteId,
  disableWorksiteInviteId,
  archiveWorksite,
  unarchiveWorksite,
  getFileUploadHistory,
  integrateHris,
  hrisSync,
  disconnectHris,
  getFinchWorksites,
  syncFinchWorksiteEmployees,
  bulkSyncFinchWorksiteEmployees,
  rolloutUsers,
  applyDiscount,
  applyDiscountSuccess,
  updateOnboardingStep,
}
