import React, { useState } from 'react'
import GridCard from 'employee-platform/shared/components/GridCard'
import { GridContainer } from 'employee-platform/shared/styles/Grid'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import AllocationHistoryTable from './components/AllocationHistoryTable'
import { PreTaxProgramTypeMap } from 'employee-platform/shared/utils'
import BudgetBreakdown from 'employee-platform/shared/components/BudgetBreakdown'
import * as FlexIcons from 'core-system/Icons/Flex'
import palette from 'core-system/Themes/palette'
import EditAllocationModal from './components/EditAllocationModal'
import Button from 'core-system/Button'
import FlexContainer from 'core-system/FlexContainer'
import UpcomingAllocations from './components/UpcomingAllocations'
import Divider from 'core-system/Divider'
import {
  Alert as AlertIcon,
  Calendar as CalendarIcon,
} from 'core-system/Icons/Misc'
import { PreTaxProgram } from 'redux/employeePlatform/employeePlatformTypes'
import moment from 'moment'
import Text from 'core-system/Text'
import PreTaxInfo from './components/PreTaxInfo'
import { screenSizes } from 'shared/RandomUtils'
import useResizeListener from 'shared/Hooks/useResizeListener'
import {
  ORCA_PILOT_GROUP_NAME,
  SSA_COMPANY_ID,
} from 'redux/config/services/Constants'

const getContribDeadlineInfo = (preTaxPrograms: {
  [key: string]: PreTaxProgram
}) => {
  if (preTaxPrograms) {
    const daysToEOM = moment().endOf('month').diff(moment(), 'days')
    const currDayOfMonth = Number(moment().format('D'))
    // Get the first active program
    const activeProgramType = Object.keys(preTaxPrograms).find(
      (programType) => preTaxPrograms[programType] !== null
    )

    // If there are no active programs return nothing
    if (!activeProgramType) {
      return null
    }

    const contribDeadlineDay =
      preTaxPrograms[activeProgramType]?.contributionDeadlineDay
    // If the employer has not set a contribution deadline day return the days to end of month
    if (!contribDeadlineDay) {
      return {
        deadlineDay: moment().endOf('month').format('D'),
        daysLeftToUpdate: daysToEOM,
      }
    }
    // If the current day is before the contribution deadline day return the number of days
    // until the deadline day, otherwise return the contribution deadline day + the days to EOM
    if (currDayOfMonth <= contribDeadlineDay) {
      return {
        deadlineDay: contribDeadlineDay,
        daysLeftToUpdate: contribDeadlineDay - currDayOfMonth,
      }
    } else {
      return {
        deadlineDay: contribDeadlineDay,
        daysLeftToUpdate: contribDeadlineDay + daysToEOM,
      }
    }
  }

  return null
}

const PreTaxOverviewView = React.memo(() => {
  const { preTaxPrograms, profileData } = useSelector(
    (state: AppState) => state.employeePlatform
  )

  const segmentNames = profileData.segments.map((data) =>
    data.name.toLowerCase()
  )

  const hasORCA =
    segmentNames.includes(ORCA_PILOT_GROUP_NAME) &&
    profileData.employerId === SSA_COMPANY_ID

  const [isMobile, setIsMobile] = useState(
    window.innerWidth <= screenSizes.mobile
  )
  const handleResize = React.useCallback(
    (width: number) => {
      setIsMobile(width <= screenSizes.mobile)
    },
    [setIsMobile]
  )
  useResizeListener(() => handleResize(window.innerWidth), [])

  const [showEditAllocationModal, setShowEditAllocationModal] = useState(false)

  const preTaxBudgetItems = preTaxPrograms
    ? Object.keys(preTaxPrograms).map((programType) => {
        return {
          name: PreTaxProgramTypeMap[programType],
          amount: preTaxPrograms[programType]?.cycleBalance,
          icon:
            programType === 'COMMUTER_TRANSIT' ||
            programType === 'COMMUTER_ORCA' ? (
              <FlexIcons.Transit color={palette.text.secondary} />
            ) : (
              <FlexIcons.Parking color={palette.text.secondary} />
            ),
        }
      })
    : null

  const contribDeadlineInfo = getContribDeadlineInfo(preTaxPrograms)

  return (
    <GridContainer numColumns={2}>
      <GridContainer numColumns={1}>
        <GridCard headerTitle='Current Balance'>
          {preTaxBudgetItems ? (
            <BudgetBreakdown
              budgetItems={
                hasORCA
                  ? preTaxBudgetItems
                  : preTaxBudgetItems.filter((item) => item.name !== 'ORCA')
              }
              summaryDirection='row'
            />
          ) : null}
        </GridCard>
        <GridCard headerTitle='Upcoming Allocation'>
          <FlexContainer flexDirection='column'>
            <UpcomingAllocations hasORCA={hasORCA} />
            <Divider margin='0.5rem 0' />
            {contribDeadlineInfo ? (
              <FlexContainer
                flexDirection='row'
                justifyContent='center'
                alignItems='center'
                marginBottom='1rem'
                marginTop='0.5rem'
              >
                {contribDeadlineInfo.daysLeftToUpdate > 3 ? (
                  <CalendarIcon
                    width='2.5rem'
                    height='2.5rem'
                    color={palette.text.secondary}
                  />
                ) : (
                  <AlertIcon
                    width='2rem'
                    height='2rem'
                    color={palette.text.secondary}
                  />
                )}
                <FlexContainer
                  flexDirection='column'
                  justifyContent='center'
                  marginLeft='0.5rem'
                >
                  <Text variant='h5'>
                    {`${contribDeadlineInfo.daysLeftToUpdate} day${
                      contribDeadlineInfo.daysLeftToUpdate === 1 ? '' : 's'
                    } left to update allocation`}
                  </Text>
                  <Text variant='action4' textColor={palette.text.placeholder}>
                    {`Allocation resets on the ${
                      contribDeadlineInfo.deadlineDay
                    }${
                      contribDeadlineInfo.deadlineDay === 21 ? 'st' : 'th'
                    } of each month`}
                  </Text>
                </FlexContainer>
              </FlexContainer>
            ) : null}
          </FlexContainer>
          <Button onClick={() => setShowEditAllocationModal(true)}>
            Manage Your Allocation
          </Button>
        </GridCard>
        <GridCard headerTitle='Allocation History'>
          <AllocationHistoryTable isMobile={isMobile} />
        </GridCard>
      </GridContainer>
      <GridContainer numColumns={1} fitContentHeight>
        <GridCard headerTitle='Pre-Tax Commuter Benefits Info'>
          <PreTaxInfo />
        </GridCard>
      </GridContainer>
      {showEditAllocationModal ? (
        <EditAllocationModal
          closeModal={() => setShowEditAllocationModal(false)}
          open={showEditAllocationModal}
          isMobile={isMobile}
          hasORCA={hasORCA}
        />
      ) : null}
    </GridContainer>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  PreTaxOverviewView.displayName = 'PreTaxOverviewView'
}

export default PreTaxOverviewView
